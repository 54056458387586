import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
   <div className="container">
        <div className="row">
        <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>İHTİYAÇLARINIZI BELİRLEYEREK SİZE ÖZEL YAZILIMLAR GELİŞTİRİYORUZ</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Başlıca Neler Yapıyoruz?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 ml-100">
            {" "}
            <img  src="img/testimonials.png" className="img-responsive" alt="FertileSoft ile Özel Yazılım Desteği..." />{" "}
          </div>
       
        </div>
      </div>
    </div>
  );
};
