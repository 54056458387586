import React from "react";

export const Services = (props) => {
  return (
  
      <div id="services" className="text-center">
     
     <div className="container">
       <div className="section-title">
         <h2>PROJE SÜREÇLERİ</h2>
         <p>
           Her türlü ihtiyaçlarınız için Yazılım Süreçleri..
         </p>
       </div>
       <div className="row">
         {props.data
           ? props.data.map((d, i) => (
               <div key={`${d.name}-${i}`} className="col-md-3">
                 {" "}
                 <i className={d.icon}></i>
                 <div className="service-desc">
                   <h3>{d.name}</h3>
                   <p>{d.text}</p>
                 </div>
               </div>
             ))
           : "loading"}
       </div>
     </div>
   </div>
   
  
  );
};
