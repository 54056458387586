import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Tostify'nin CSS dosyasını dahil edin
import { useEffect } from "react";
import { api } from "../App";

const initialState = {
  name: "",
  email: "",
  message: "",
  phone: "",
};

export const Contact = (props) => {
  const [{ name, email, message, phone }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState(initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      debugger
      window.gtag('event', 'conversion', {'send_to': 'AW-11487151963/MyXMCJylua8ZENuOwOUq'});
      const request = {
        name: name,
        phone: phone,
        email: email,
        message: message,
      };
      const response = await axios.post(
        api+"/Mail/Send",
        request
      );
      clearState();
      toast.success("Mailiniz Başarıyla Gönderilmiştir. En Kısa Süre İçinde Dönüş Yapılacaktır..",{
        position: "top-center"
      });
    } catch (error) {
      toast.error(error?.response?.data || error?.stack,{
        position: "top-center",
        
      });
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>BİZİMLE İLETİŞİME GEÇİNİZ !</h2>
                <p>Lütfen İhtiyaçlarınızı açıklayıp, isteklerinizi bize mail yolu ile iletiniz.En kısa sürede mailinize dönüş yapılacaktır...</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Ad / Soyad"
                        required
                        value={name}
                        onChange={handleChange}
                        style={{borderRadius:5}}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="phone"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Telefon"
                        required
                        value={phone}
                        onChange={handleChange}
                        style={{borderRadius:5}}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                        style={{borderRadius:5}}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="İhtiyaç duyduğunuz yazılım detaylarını açıklayınız.."
                    required
                    value={message}
                    onChange={handleChange}
                    style={{borderRadius:5}}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <div className="text-center">
                  <button type="submit" className="btn btn-custom btn-lg text-center">Gönder</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>İletişim Bilgileri</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Adres
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={"https://www.linkedin.com/company/102571908/admin/feed/posts/?feedType=following"} target="_blank">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.instagram.com/fertilesoftware/"} target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>© FertileSoft Information Technologies Inc. 2024. All Rights Reserved. Terms of Use</p>
        </div>
      </div>
      <ToastContainer /> {/* Tostify bildirimlerini görüntülemek için ToastContainer ekleyin */}
    </div>
  );
};
